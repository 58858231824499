<template>
  <div class="blog">
    <div class="tile">
      <h5 class="tile-title">New Post</h5>
      <div class="tile-body">
        <ValidationObserver
          tag="form"
          v-slot="{ handleSubmit }"
          ref="newBlogPostForm"
          class="mt-0"
        >
          <div class="row">
            <div class="col">
              <div class="form-group">
                <ValidationProvider
                  tag="div"
                  rules="required"
                  name="Language"
                  v-slot="{ errors }"
                  class="mt-3 mb-3"
                >
                  <label for="language" class="form-label">Language:</label>
                  <select
                    v-model="newEditPost_data.language"
                    :class="{ 'form-select': true, 'is-invalid': errors[0] }"
                    @change="resetTranslatedTitle"
                  >
                    <option value="en">English</option>
                    <option value="arb">Arabic</option>
                  </select>
                  <span class="error">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <ValidationProvider
                  tag="div"
                  rules="required"
                  name="Title"
                  v-slot="{ errors }"
                  class="mb-3"
                >
                  <label for="title" class="col-form-label">Title:</label>
                  <input
                    type="text"
                    v-model="newEditPost_data.title"
                    id="title"
                    :class="{ 'form-control': true, 'is-invalid': errors[0] }"
                  />
                  <span class="error">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <ValidationProvider
                  tag="div"
                  rules="required"
                  name="Translated Title"
                  v-slot="{ errors }"
                  class="mb-3"
                  v-if="isTranslated"
                >
                  <label for="title" class="col-form-label"
                    >Translated Title:</label
                  >
                  <input
                    type="text"
                    v-model="newEditPost_data.translatedTitle"
                    id="translatedTitle"
                    :class="{ 'form-control': true, 'is-invalid': errors[0] }"
                  />
                  <span class="error">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <ValidationProvider
                  tag="div"
                  rules="required"
                  name="Host"
                  v-slot="{ errors }"
                  class="mb-3"
                >
                  <label for="createdFor" class="form-label">Host:</label>
                  <select
                    v-model="newEditPost_data.createdFor"
                    :class="{ 'form-select': true, 'is-invalid': errors[0] }"
                  >
                    <option value="edr">EDR</option>
                    <option value="benor">BENOR</option>
                  </select>
                  <span class="error">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <ValidationProvider
                  tag="div"
                  rules="required"
                  name="Section"
                  v-slot="{ errors }"
                  class="mb-3"
                >
                  <label for="section" class="form-label">Section:</label>
                  <select
                    v-model="newEditPost_data.section"
                    :class="{ 'form-select': true, 'is-invalid': errors[0] }"
                  >
                    <option
                      v-for="(section, index) in sectionList"
                      :key="index"
                      :value="section"
                    >
                      {{ section }}
                    </option>
                  </select>
                  <span class="error">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <ValidationProvider
                  ref="image"
                  tag="div"
                  rules="required|image"
                  name="Image"
                  v-slot="{ errors }"
                  class="mb-3"
                >
                  <label for="image" class="form-label me-md-2">Image:</label>
                  <input
                    v-show="!existingBlog"
                    type="file"
                    id="image"
                    @change="checkFile"
                    :class="{ 'form-control': true, 'is-invalid': errors[0] }"
                  />
                  <div v-show="existingBlog">
                    <b>{{ newEditPost_data.image.name }} </b>
                    <button
                      type="button"
                      class="btn btn-primary btn-sm mt-0"
                      @click="selectFile"
                    >
                      <i class="fas fa-edit"></i>
                    </button>
                  </div>
                  <span class="error">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <ValidationProvider
                  tag="div"
                  rules="required"
                  name="Reading time"
                  v-slot="{ errors }"
                  class="mb-3"
                >
                  <label for="readingTime" class="col-form-label"
                    >Reading Time:</label
                  >
                  <input
                    type="text"
                    v-model.number="newEditPost_data.readingTime"
                    id="readingTime"
                    :class="{ 'form-control': true, 'is-invalid': errors[0] }"
                  />
                  <span class="error">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <ValidationProvider
                  tag="div"
                  rules="required"
                  name="content"
                  v-slot="{ errors, validate }"
                  class="editor mb-3"
                >
                  <label for="content" class="form-label">Content</label>
                  <span :class="{ errorEditorIcon: errors[0] }"></span>
                  <editor
                    @change="validate($event)"
                    v-model="newEditPost_data.content"
                    :class="{ 'form-control': true, 'is-invalid': errors[0] }"
                  />
                  <span class="error">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="d-grid d-md-flex justify-content-md-end">
            <button
              class="btn btn-secondary me-md-2"
              type="button"
              @click.prevent="resetForm"
            >
              Reset
            </button>
            <button
              v-if="!existingBlog"
              type="submit"
              class="btn btn-primary"
              @click.prevent="handleSubmit(createPost)"
            >
              Create
              <loader :isLoading="isLoading"></loader>
            </button>
            <button
              v-else
              type="submit"
              class="btn btn-primary me-md-2"
              @click.prevent="handleSubmit(editPost)"
            >
              Update
              <loader :isLoading="isLoading"></loader>
            </button>
          </div>
        </ValidationObserver>
      </div>
    </div>
    <div class="tile">
      <h5 class="tile-title mb-4">Posts</h5>
      <div class="mt-2 mb-3">
        <label for="language" class="form-label">Language:</label>
        <select v-model="selectedLang" class="form-select" @change="getPosts">
          <option value="en">English</option>
          <option value="arb">Arabic</option>
        </select>
      </div>
      <vue-good-table
        ref="postsTable"
        :rows="posts"
        :columns="columns"
        @on-selected-rows-change="selectRow"
        :pagination-options="{
          enabled: true,
        }"
        :search-options="{
          enabled: true,
          placeholder: 'Search',
        }"
        :select-options="{ enabled: true, selectOnCheckboxOnly: true }"
      >
        <div slot="selected-row-actions">
          <button
            type="button"
            class="btn btn-danger"
            @click.prevent="deletePost"
          >
            Delete
            <loader :isLoading="isLoading"></loader>
          </button>
        </div>
        <template slot="table-row" slot-scope="props">
          <div v-if="props.column.field == 'buttons'" class="text-center">
            <button
              type="button"
              class="btn btn-primary"
              @click.prevent="selectPost(props.row)"
            >
              <i class="fas fa-edit"></i>
            </button>
          </div>
        </template>
        <div slot="emptystate">
          <span>No data</span>
        </div>
      </vue-good-table>
    </div>
  </div>
</template>
<script>
import { getters, mutations } from "../store";
import editor from "../components/editor";
import loader from "../components/loader";
export default {
  data() {
    return {
      newEditPost_data: {
        title: "",
        translatedTitle: "",
        section: "",
        image: {
          name: "",
          file: "",
        },
        language: "",
        readingTime: "",
        content: "",
        createdBy: "",
        createdFor: "",
      },
      sectionList: ["General", "Marketing"],
      columns: [
        {
          label: "Title",
          field: "title",
        },
        {
          label: "Translated Title",
          field: "translatedTitle",
          hidden: false,
        },
        {
          label: "Created By",
          field: "createdBy",
        },
        {
          label: "Created For",
          field: "createdFor",
          formatFn: (value) => {
            return value.toUpperCase();
          },
        },
        {
          label: "Section",
          field: "section",
        },
        {
          label: "Created On",
          field: "createdAt",
        },
        {
          label: "Updated On",
          field: "updatedAt",
          type: "date",
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
          dateOutputFormat: "dd/MM/yyyy",
        },
        {
          label: "",
          field: "buttons",
          hidden: false,
        },
      ],
      selectedLang: "en",
      selectedRowsList: [],
      isLoading: false,
    };
  },
  components: {
    editor,
    loader,
  },
  mounted() {
    this.getPosts();
  },
  computed: {
    userLoggedIn() {
      return getters.userLoggedIn();
    },
    posts() {
      return getters.posts();
    },
    existingBlog() {
      return "_id" in this.newEditPost_data;
    },
    isTranslated() {
      return (
        this.newEditPost_data.language !== "" &&
        this.newEditPost_data.language !== "en"
      );
    },
    showNewPostInTable() {
      return this.newEditPost_data.language === this.selectedLang;
    },
  },
  watch: {
    userLoggedIn: {
      handler({ name }) {
        this.newEditPost_data.createdBy = name;
      },
      deep: true,
      immediate: true,
    },
    selectedLang: {
      handler(lang) {
        this.columns[1].hidden = lang === "en";
      },
      immediate: true,
    },
  },
  methods: {
    selectFile() {
      document.getElementById("image").click();
    },
    async checkFile(event) {
      const { valid } = await this.$refs.image.validate(event);
      if (valid) {
        this.handleFile(event.target.files[0]);
      }
    },
    handleFile(file) {
      let reader = new FileReader();
      reader.onloadend = () => {
        this.newEditPost_data.image = {
          name: file.name,
          file: reader.result,
        };
      };
      reader.readAsDataURL(file);
    },
    selectPost({
      _id,
      title,
      translatedTitle,
      section,
      image,
      language,
      readingTime,
      content,
      createdBy,
      createdFor,
    }) {
      window.scrollTo(0, 0);
      this.newEditPost_data = {
        _id: _id,
        title: title,
        translatedTitle: translatedTitle,
        section: section,
        image: {
          name: image.name,
          file: image.file,
        },
        language: language,
        readingTime: readingTime,
        content: content,
        createdBy: createdBy,
        createdFor: createdFor,
      };
      this.$refs.image.value = this.newEditPost_data.image.file;
    },
    selectRow({ selectedRows }) {
      this.selectedRowsList = selectedRows;
    },
    getPosts() {
      this.$axios({
        url: "/blog",
        params: { lang: this.selectedLang },
      })
        .then(({ data: { blogsList } }) => {
          mutations.setPosts(blogsList);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createPost() {
      this.isLoading = true;
      this.$axios
        .post("/blog/create", this.newEditPost_data)
        .then(({ data: { success, message, createdBlog } }) => {
          if (success) {
            this.resetForm();
            if (this.showNewPostInTable) {
              mutations.setPost(createdBlog);
            }
          }
          window.scrollTo(0, 0);
          this.isLoading = false;
          mutations.showToast({ success, message });
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    editPost() {
      this.isLoading = true;
      this.$axios
        .patch(`blog/${this.newEditPost_data._id}`, this.newEditPost_data)
        .then(({ data: { success, message, updatedBlog } }) => {
          if (success) {
            this.resetForm();
            mutations.updatePost(updatedBlog);
          }
          window.scrollTo(0, 0);
          this.isLoading = false;
          mutations.showToast({ success, message });
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    deletePost() {
      this.isLoading = true;
      this.$axios
        .delete("/blog", {
          data: { itemsToDelete: this.selectedRowsList },
        })
        .then(({ data: { success, message } }) => {
          if (success) {
            mutations.deletePost(this.selectedRowsList);
          }
          window.scrollTo(0, 0);
          this.isLoading = false;
          mutations.showToast({ success, message });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    resetTranslatedTitle() {
      this.newEditPost_data.translatedTitle = "";
    },
    resetForm() {
      this.$nextTick(() => {
        this.newEditPost_data = {
          title: "",
          translatedTitle: "",
          section: "",
          image: {
            name: "",
            file: "",
          },
          language: "",
          readingTime: "",
          content: "",
          createdBy: this.userLoggedIn.name,
          createdFor: "",
        };
        window.scrollTo(0, 0);
        this.$refs.image.value = null;
        this.$refs.newBlogPostForm.reset();
        document.getElementById("image").value = null;
      });
    },
  },
};
</script>
