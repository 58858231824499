<template>
  <span v-show="isLoading" class="align-text-bottom">
    <i class="fas fa-circle-notch fa-spin align-middle"></i>
  </span>
</template>
<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      require: true,
    },
  },
};
</script>